<template>
  <div class="mt-3">
    <div class="row">
      <div class="col-12 col-sm-4">
        <v-text-field
          v-model="destination_address.name"
          class
          label="Name"
          clearable
          outlined
          dense
          :error-messages="destinationNameErrors"
          @input="$v.destination_address.name.$touch()"
          @blur="$v.destination_address.name.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          class
          v-model="destination_address.company"
          label="Company"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-autocomplete
          @change="onCollectionCountryChange"
          v-model="destination_address.country"
          :label="getSuggestedRegion('country')"
          item-text="text"
          item-value="index"
          :items="serverData.countries"
          clearable
          outlined
          dense
          :error-messages="destinationCountryErrors"
          @input="$v.destination_address.country.$touch()"
          @blur="$v.destination_address.country.$touch()"
        ></v-autocomplete>
      </div>

      <div class="col-12 col-sm-4">
        <v-combobox
          v-model="destination_address.state"
          :search-input.sync="destination_state_text"
          :items="destinationAddressServerData.states"
          :disabled="isCollectionStatesLoaded"
          :rules="[validationValueCheck('destination_state')]"
          item-text="title"
          item-value="id"
          :label="getSuggestedRegion('state')"
          persistent-hint
          outlined
          clearable
          dense
          :error-messages="destinationStateErrors"
          @input="$v.destination_address.state.$touch()"
          @blur="$v.destination_address.state.$touch()"
          @change="onCollectionStateChange"
          @keyup="updateValue('destination_state')"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    destination_state_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'state',
                        destination_address.country,
                        onCollectionCountryChange,
                        destination_state_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-combobox
          v-model="destination_address.city"
          :search-input.sync="destination_city_text"
          :items="destinationAddressServerData.cities"
          :rules="[validationValueCheck('destination_city')]"
          :disabled="isCollectionCitiesLoaded"
          item-text="title"
          item-value="id"
          :label="getSuggestedRegion('city')"
          persistent-hint
          outlined
          dense
          clearable
          :error-messages="destinationCityErrors"
          @input="$v.destination_address.city.$touch()"
          @blur="$v.destination_address.city.$touch()"
          @change="onCollectionCityChange"
          @keyup="updateValue('destination_city')"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    destination_city_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'city',
                        destination_address.state.id,
                        onCollectionStateChange,
                        destination_city_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-combobox
          v-model="destination_address.area"
          :search-input.sync="destination_area_text"
          :items="destinationAddressServerData.areas"
          :rules="[validationValueCheck('destination_area')]"
          item-text="title"
          item-value="id"
          :label="getSuggestedRegion('area')"
          persistent-hint
          outlined
          :disabled="isCollectionAreasLoaded"
          dense
          @keyup="updateValue('destination_area')"
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  No results matching "<strong>{{
                    destination_area_text
                  }}</strong
                  >".
                  <button
                    class="btn btn-info pl-2"
                    @click="
                      createNewItem(
                        'area',
                        destination_address.city.id,
                        onCollectionCityChange,
                        destination_area_text
                      )
                    "
                  >
                    Create new
                  </button>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-combobox>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          class
          v-model="destination_address.address"
          label="Address"
          clearable
          outlined
          dense
          :error-messages="destinationAddressErrors"
          @input="$v.destination_address.address.$touch()"
          @blur="$v.destination_address.address.$touch()"
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <v-text-field
          class
          v-model="destination_address.address_2"
          label="Address 2"
          clearable
          outlined
          dense
        ></v-text-field>
      </div>
      <div class="col-12 col-sm-4">
        <VueTelInputVuetify
          v-model="destination_address.phone"
          label="Phone"
          type="number"
          clearable
          outlined
          dense
          :inputOptions="{ showDialCode: false, tabindex: 0 }"
          mode="international"
          disabledFetchingCountry
          defaultCountry="AE"
          :error-messages="destinationPhoneErrors"
          @input="$v.destination_address.phone.$touch()"
          @blur="$v.destination_address.phone.$touch()"
        ></VueTelInputVuetify>
      </div>
    </div>
    <CreateLocation
      :location-id="getLocationId"
      :location-type="getLocationType"
      :location-function="getLocationFunc"
      :locationSetValue="setTextValue"
      :initValue="getLocationInitValue"
      ref="location"
    ></CreateLocation>
  </div>
</template>
<script>
import CreateLocation from "@/own/components/orderManagement/returned/CreateReturned/CreateLocation.vue";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import ApiService from "@/core/services/api.service";
import VueTelInputVuetify from "vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue";

export default {
  name: "DestinationAddress",
  mixins: [validationMixin],
  props: ["pageLoader"],
  components: { CreateLocation, VueTelInputVuetify },
  validations() {
    return {
      destination_address: {
        name: { required },
        address: { required },
        city: { required },
        country: { required },
        phone: { required },
        state: { required },
      },
    };
  },
  data: () => {
    return {
      destination_address: {
        name: null,
        company: null,
        address: null,
        area: null,
        city: null,
        country: null,
        phone: null,
        state: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
      },

      location_type: null,
      locaiton_id: null,
      location_function: null,

      destination_state_text: null,
      destination_city_text: null,
      destination_area_text: null,

      destinationAddressServerData: {},
    };
  },
  computed: {
    getLocationId() {
      return this.locaiton_id;
    },
    getLocationType() {
      return this.location_type;
    },
    getLocationFunc() {
      return this.location_function;
    },
    destinationNameErrors: function () {
      return this.handleFormValidation("name", this, "destination_address");
    },
    destinationAddressErrors: function () {
      return this.handleFormValidation("address", this, "destination_address");
    },
    destinationCityErrors: function () {
      return this.handleFormValidation("city", this, "destination_address");
    },
    destinationPhoneErrors: function () {
      return this.handleFormValidation("phone", this, "destination_address");
    },
    destinationCountryErrors: function () {
      return this.handleFormValidation("country", this, "destination_address");
    },
    destinationStateErrors: function () {
      return this.handleFormValidation("state", this, "destination_address");
    },
    destinationBuildingNoErrors: function () {
      return this.handleFormValidation(
        "building_no",
        this,
        "destination_address"
      );
    },
    destinationUnitNoErrors: function () {
      return this.handleFormValidation("unit_no", this, "destination_address");
    },
    destinationAdditionalNoErrors: function () {
      return this.handleFormValidation(
        "additional_no",
        this,
        "destination_address"
      );
    },
    getLocationInitValue() {
      return this.location_initValue;
    },
    serverData: function () {
      return this.$store.getters.getPENDINGRETURNSServerData;
    },
    isCollectionAreasLoaded: function () {
      return !this.destinationAddressServerData.areas;
    },
    isCollectionCitiesLoaded: function () {
      return !this.destinationAddressServerData.cities;
    },
    isCollectionStatesLoaded: function () {
      return !this.destinationAddressServerData.states;
    },
    isFormValid: function () {
      return !this.$v.$invalid;
    },
    editData() {
      return this.$store.getters.getPENDINGRETURNSEditData;
    },
  },
  methods: {
    async onCollectionCountryChange(val, safe = false, is_id = false) {
      if (val) {
        this.pageLoader(true);
        let data = is_id ? { country: val } : { country: val };
        await ApiService.post("/address/states/search", data)
          .then((response) => {
            this.destinationAddressServerData.states = response.data.states;
            if (!safe) {
              //
            }
            let i = { ...this.destinationAddressServerData };
            this.destinationAddressServerData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onCollectionStateChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { state: null };
        if (is_id) {
          data.state = val;
        } else {
          let id = null;
          try {
            id = this.destinationAddressServerData.states.filter(
              (state) => state.title == val.title
            )[0].id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }
          data.state = id;
        }
        this.pageLoader(true);

        await ApiService.post("/address/cities/search", data)
          .then((response) => {
            this.destinationAddressServerData.cities = response.data.cities;

            if (!safe) {
              //
            }
            let i = { ...this.destinationAddressServerData };
            this.destinationAddressServerData = i;

            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);

            this.dialog = false;
          });
      }
    },
    async onCollectionCityChange(val, safe = false, is_id = false) {
      if (val) {
        let data = { city: null };
        if (is_id) {
          data.city = val;
        } else {
          let id = null;
          try {
            id = this.destinationAddressServerData.cities.filter(
              (city) => city.title == val.title
            )[0].id;
          } catch {
            return;
          }
          if (!id) {
            return;
          }

          data.city = id;
        }
        this.pageLoader(true);
        await ApiService.post("/address/areas/search", data)
          .then((response) => {
            this.destinationAddressServerData.areas = response.data.areas;
            if (!safe) {
              // this.destination_address.area = null;
            }
            let i = { ...this.destinationAddressServerData };
            this.destinationAddressServerData = i;
            this.pageLoader(false);
          })
          .catch(() => {
            this.pageLoader(false);
            this.dialog = false;
          });
      }
    },
    validationValueCheck(type) {
      let validation = true;
      switch (type) {
        case "destination_state":
          try {
            if (
              this.destinationAddressServerData.states.filter(
                (state) => state.title == this.destination_state_text
              ).length === 0
            ) {
              validation = "Please, select valid state!";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;

        case "destination_city":
          try {
            if (
              this.destinationAddressServerData.cities.filter(
                (city) => city.title == this.destination_city_text
              ).length === 0
            ) {
              validation = "Please, select valid city!";
              // this.serverData.areas = null;
            }
          } catch {
            break;
          }

          break;

        case "destination_area":
          try {
            if (
              this.destinationAddressServerData.areas.filter(
                (area) => area.title == this.destination_area_text
              ).length === 0
            ) {
              validation = "Please, select valid area!";
            }
          } catch {
            break;
          }

          break;
      }
      return validation;
    },
    updateValue(type) {
      let timer;
      const waitTime = 1250;
      clearTimeout(timer);
      timer = setTimeout(() => {
        this.onBlurValueCheck(type);
      }, waitTime);
    },
    onBlurValueCheck(type) {
      switch (type) {
        case "destination_state":
          if (
            this.destinationAddressServerData.states.filter(
              (state) => state.title == this.destination_state_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.state_text = "";
              // this.serverData.cities = null;
              // this.serverData.areas = null;
            }, 2000);
          } else {
            this.onCollectionStateChange(
              this.destinationAddressServerData.states.filter(
                (state) => state.title == this.destination_state_text
              )[0]
            );
          }

          break;
        case "destination_city":
          if (
            this.destinationAddressServerData.cities.filter(
              (city) => city.title == this.destination_city_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.city_text = "";
              // this.serverData.areas = null;
            }, 2000);
          } else {
            this.onCollectionCityChange(
              this.destinationAddressServerData.cities.filter(
                (city) => city.title == this.destination_city_text
              )[0]
            );
          }
          break;
        case "destination_area":
          if (
            this.destinationAddressServerData.areas.filter(
              (area) => area.title == this.destination_area_text
            ).length === 0
          ) {
            setTimeout(() => {
              // this.area_text = "";
            }, 2000);
          } else {
            this.destination_address.area =
              this.destinationAddressServerData.areas.filter(
                (area) => area.title == this.destination_area_text
              )[0].title;
          }
          break;
      }
    },
    async setTextValue(type, value = "") {
      switch (type) {
        case "destination_state":
          this.destination_state_text = value;

          if (value !== "") {
            this.destination_address.state =
              this.destinationAddressServerData.states.filter(
                (state) => state.title == this.destination_state_text
              )[0];
            await this.onCollectionStateChange(
              this.destinationAddressServerData.states.filter(
                (state) => state.title == this.destination_state_text
              )[0]
            );
          }

          break;
        case "destination_city":
          this.city_text = value;

          if (value !== "") {
            this.destination_address.city =
              this.destinationAddressServerData.cities.filter(
                (city) => city.title == this.city_text
              )[0];
            await this.onCollectionCityChange(
              this.destinationAddressServerData.cities.filter(
                (city) => city.title == this.city_text
              )[0]
            );
          }
          break;
        case "destination_area":
          this.destination_area_text = value;
          break;
      }
    },
    handleFormValidation(fieldName, vueObj, dataName = "formData") {
      const errors = [];
      if (!vueObj.$v[`${dataName}`][fieldName].$dirty) return errors;
      if ("required" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].required &&
          errors.push("This field is required");
      }
      if ("maxValue" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].maxValue &&
          errors.push("This field must be greater than 0");
      }
      if ("url" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].url &&
          errors.push("This url is invalid");
      }
      if ("numeric" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].numeric &&
          errors.push("This must be a number");
      }
      if ("email" in vueObj.$v[`${dataName}`][fieldName]) {
        !vueObj.$v[`${dataName}`][fieldName].email &&
          errors.push("This email address is invalid");
      }
      return errors;
    },
    createNewItem(type, id, func, value = "") {
      this.location_type = type;
      this.locaiton_id = id;
      this.location_function = func;
      this.location_initValue = value;
      this.$nextTick(() => {
        this.$refs.location.toggleModal();
      });
    },
    checkErrors() {
      if (this.$v.$invalid) {
        this.$v.$touch();
        return;
      }
    },
    clearFields() {
      this.destination_address = {
        name: null,
        company: null,
        address: null,
        area: null,
        city: null,
        country: null,
        phone: null,
        state: null,
        building_no: null,
        unit_no: null,
        additional_no: null,
      };
      this.location_type = null;
      this.locaiton_id = null;
      this.location_function = null;

      this.destination_state_text = null;
      this.destination_city_text = null;
      this.destination_area_text = null;

      this.destinationAddressServerData = {};

      //reset validation
      this.$v.$reset();
    },
    async setDeafultCollectionAddress() {
      this.destination_address.country = 229;
      let state = null;
      let city = null;

      await this.onCollectionCountryChange(229);
      state = this.destinationAddressServerData.states.find(
        (item) => item.id === 4182
      );

      await this.onCollectionStateChange(state, true);
      city = this.destinationAddressServerData.cities.find(
        (item) => item.id === 48468
      );
      await this.onCollectionCityChange(city, true);

      this.destination_address = {
        ...this.destination_address,
        state,
        city,
      };
    },
    async getEditData() {
      const data = this.editData.destinationAddress;
      let state = null;
      let city = null;
      let area = null;

      await this.onCollectionCountryChange(data.country);
      try {
        if (data.state) {
          state = this.destinationAddressServerData?.states.find(
            (item) => item.id === data.state
          );
          await this.onCollectionStateChange(state, true);
        }
        if (data.city) {
          city = this.destinationAddressServerData?.cities.find(
            (item) => item.id === data.city
          );
          await this.onCollectionCityChange(city, true);
        }
        if (data.area) {
          // await this.onCollectionCityChange(city, true);
          area = this.destinationAddressServerData?.areas.find(
            (item) => item.id === data.area
          );
        }
      } catch {
        //
      }

      this.destination_address = {
        ...this.editData.destinationAddress,
        state,
        city,
        area,
      };
      // console.log(state);
    },
    getSuggestedRegion(type) {
      const typeCapitalize = type.charAt(0).toUpperCase() + type.slice(1);

      if (
        this.serverData.return_management &&
        this.serverData.return_management[`c_${type}`]
      ) {
        return `${typeCapitalize} (Suggested: ${
          this.serverData.return_management[`c_${type}`]
        })`;
      } else {
        return typeCapitalize;
      }
    },
  },
};
</script>
