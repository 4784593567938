<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="dialogWidth"
    v-if="isDataLoadedFromServer"
    style="background-color: white"
  >
    <div class="bg-white poppins modal-root">
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">
          {{ actionType === "create" ? "Create" : "Edit" }} Return
        </h4>
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
      </div>

      <div class="d-flex flex-column mx-5 modal-body">
        <!--begin::Body-->
        <v-stepper
          v-model="tab"
          non-linear
          outlined
          rounded="4"
          flat
          v-if="isStepperReady"
          @change="updateValidationStateOfSteps"
        >
          <v-stepper-header class="step-head-main">
            <v-stepper-step
              v-for="(item, i) in categories"
              :key="i"
              class="step-head"
              complete
              editable
              complete-icon="mdi-check-circle"
              edit-icon="mdi-check"
              error-icon="mdi-alert-circle"
              :step="i"
              :rules="[
                () => {
                  return item.valid;
                },
              ]"
            >
              {{ item.name }}
              <small v-if="!item.valid">Invalid</small>
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="0" key="Order Details" class="pt-2">
              <!-- external order details -->
              <ExternalOrderDetails
                ref="externalOrderDetails"
                v-if="returnType === 'external'"
              ></ExternalOrderDetails>
              <!-- internal order details -->
              <InternalOrderDetails
                ref="internalOrderDetails"
                v-if="returnType === 'iq'"
              ></InternalOrderDetails>
            </v-stepper-content>
            <v-stepper-content step="1" class="pt-2">
              <CollectionAddress
                ref="collectionAddress"
                :pageLoader="pageLoader"
              ></CollectionAddress>
            </v-stepper-content>
            <v-stepper-content
              v-if="destinationAddressType === 4"
              :step="2"
              class="pt-2"
            >
              <DestinationAddress
                ref="destinationAddress"
                :pageLoader="pageLoader"
              ></DestinationAddress>
            </v-stepper-content>
            <v-stepper-content
              :step="destinationAddressType === 4 ? 3 : 2"
              class="pt-2"
            >
              <SkuDatatable
                :setSku="setSkus"
                :skus="formData.skus"
                :skuItem="[]"
                :clientId="userId"
                :is_unit_price_required="get_is_unit_price_required"
              ></SkuDatatable>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div class="d-block w-100 pt-4">
          <div class="d-flex align-center justify-content-between">
            <v-btn width="120" @click="showPrevOrder(itemForAction.id)"
              >previous
            </v-btn>
            <div
              v-if="returnType === 'external'"
              class="d-flex align-center justify-content-between"
            >
              <v-btn
                v-if="backState"
                class="order-1 order-sm-1 mx-2"
                @click="nextStep('prev')"
                color="primary"
                dark
                ><v-icon>mdi-arrow-left</v-icon></v-btn
              >
              <div v-else class="order-1 order-sm-1"></div>
              <div
                class="order-2 order-sm-2 d-flex flex-wrap justify-content-center align-center"
                :class="{ invisible: nextState }"
              >
                <!-- <button
                        type="submit"
                        class="btn btn-danger px-5 py-3 mx-2 order-2 mt-1 mt-sm-0"
                        @click="toggleModal"
                    >
                      Close
                    </button> -->
                <button
                  type="submit"
                  class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                  @click="submit"
                >
                  Update information and Audit
                </button>
                <button
                  type="reset"
                  class="btn btn-light-danger px-5 py-3 mx-2"
                  @click="showCancelModal"
                >
                  Cancel Order
                </button>

                <button
                  type="submit"
                  class="btn btn-warning px-5 py-3"
                  @click="showHoldOnModal"
                >
                  On Hold
                </button>
              </div>
              <v-btn
                v-if="nextState"
                class="order-3 order-sm-3 mx-2"
                @click="nextStep('next')"
                color="primary"
                dark
                ><v-icon>mdi-arrow-right</v-icon></v-btn
              >
              <div v-else class="order-3 order-sm-3"></div>
            </div>
            <div v-else class="d-flex align-center justify-content-between">
              <button
                type="submit"
                class="btn btn-info px-5 py-3 mx-2 order-1 mt-1 mt-sm-0"
                @click="submit"
              >
                Update information and Audit
              </button>
              <button
                type="reset"
                class="btn btn-light-danger px-5 py-3 mx-2"
                @click="showCancelModal"
              >
                Cancel Order
              </button>

              <button
                type="submit"
                class="btn btn-warning px-5 py-3"
                @click="showHoldOnModal"
              >
                On Hold
              </button>
            </div>

            <!--              next btn-->
            <v-btn width="120" @click="() => showNextOrder(itemForAction.id)"
              >next
            </v-btn>
          </div>
        </div>
      </div>
    </div>

    <!--    dialogs-->
    <v-dialog v-model="dialog2" scrollable max-width="300px">
      <v-card>
        <v-card-title>Select Hold On Reason</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px">
          <v-radio-group v-model="holdOn_reason" column>
            <v-radio
              v-for="item in extraData.hold_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            />
          </v-radio-group>

          <v-menu
            v-if="holdOn_reason === 1030"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="holdOn_date"
                label="Date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              />
            </template>
            <v-date-picker v-model="holdOn_date" @input="menu2 = false" />
          </v-menu>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog2 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionOnHold">
            On hold
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog3" scrollable max-width="300px">
      <v-card>
        <v-card-title>Cancel Reason</v-card-title>
        <v-divider />
        <v-card-text style="height: 300px">
          <v-radio-group v-model="cancel_reason" column>
            <v-radio
              v-for="item in extraData.cancel_reasons"
              :label="item.text"
              :value="item.index"
              :key="item.value"
            />
          </v-radio-group>
        </v-card-text>
        <v-divider />
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="dialog3 = false">
            Close
          </v-btn>
          <v-btn color="blue darken-1" text @click="actionCancel">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import {
  UPDATE_TABLE_DATA,
  SET_SERVER_DATA,
  SET_CREATE_RETURN_TYPE,
  SET_EDIT_DATA,
  SET_SELECTED_CUSTOMER,
  SET_DESTINATION_ADDRESS_TYPE,
  SET_COLLECTION_ADDRESS_TYPE,
  SET_CUSTOM_DESTINATION_ADDRESS,
  SET_CUSTOM_COLLECTION_ADDRESS,
} from "@/core/services/store/pendingReturn.module";
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";
import ExternalOrderDetails from "@/own/components/orderManagement/returned/external/ExternalOrderDetails.vue";
import InternalOrderDetails from "@/own/components/orderManagement/returned/internal/InternalOrderDetails.vue";
import CollectionAddress from "@/own/components/orderManagement/returned/external/CollectionAddress.vue";
import DestinationAddress from "@/own/components/orderManagement/returned/external/DestinationAddress.vue";
import SkuDatatable from "@/own/components/orderManagement/returned/external/SkuDatatable.vue";
import { SET_PAGE_LOADING } from "@/core/services/store/config.module";

import { validationMixin } from "vuelidate";
// import { required, requiredIf } from "vuelidate/lib/validators";

export default {
  name: "ActionDialog",
  components: {
    ExternalOrderDetails,
    InternalOrderDetails,
    CollectionAddress,
    DestinationAddress,
    SkuDatatable,
  },
  props: {
    showNextOrder: {
      required: true,
      type: Function,
    },
    showPrevOrder: {
      required: true,
      type: Function,
    },
    extraData: {
      required: true,
      type: Object,
    },
    pageLoader: {
      required: true,
      type: Function,
    },
  },
  mixins: [validationMixin],
  validations() {
    return {};
  },
  data: () => ({
    dialog: false,
    dialog2: false,
    dialog3: false,
    menu2: false,
    cancel_reason: null,
    holdOn_reason: null,
    holdOn_date: null,
    tab: 0,
    formData: {
      skus: [],
    },
  }),
  methods: {
    showCancelModal() {
      this.dialog3 = !this.dialog3;
    },
    actionCancel() {
      if (this.cancel_reason) {
        this.pageLoader(true);
        this.dialog3 = false;
        const data = {
          order_ids: [this.itemForAction.id],
          reason: this.cancel_reason,
        };
        ApiService.post(`/order_management/pending_audit_returns/cancel`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Cancel reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    showHoldOnModal() {
      this.dialog2 = !this.dialog2;
    },
    actionOnHold() {
      if (this.holdOn_reason) {
        this.pageLoader(true);
        this.dialog2 = false;
        const data = {
          order_ids: [this.itemForAction.id],
          reason: this.holdOn_reason,
          scheduled_date: this.holdOn_date,
        };
        ApiService.post(`/order_management/pending_audit_orders/hold`, data)
          .then(() => {
            Swal.fire({
              title: "Updated",
              text: `Hold on reason registered!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.toggleModal();
            this.pageLoader(false);
            this.refresher();
          })
          .catch(() => {
            this.pageLoader(false);
          });
      }
    },
    toggleModal(id) {
      if (this.dialog) {
        this.clearFields();
      } else if (!this.dialog) {
        if (this.actionType === "edit") {
          this.loadEditDataFromServer(id);
        } else if (
          this.actionType === "create" &&
          this.returnType === "external"
        ) {
          setTimeout(() => {
            this.$refs.collectionAddress.setDeafultCollectionAddress();
          }, 50);
        }
      }
      this.dialog = !this.dialog;
    },
    async loadEditDataFromServer(id) {
      this.pageLoader(true);
      let data = { id: id.id };
      await ApiService.post(
        `/order_management/pending_audit_returns/edit`,
        data
      )
        .then((response) => {
          this.$store.commit(
            SET_SELECTED_CUSTOMER,
            response.data.return_management.customer_id
          );
          this.$store.commit(SET_SERVER_DATA, response.data);
          this.$store.commit(
            SET_CREATE_RETURN_TYPE,
            response.data.return_management.source_of_order
          );
          this.updateValidationStateOfSteps();

          const data = response.data.return_management;
          const orderDetails = {
            customer_id: data.customer_id,
            client_reference: data.client_reference,
            warehouse_id: data.warehouse_id,
            return_type_id: data.return_type_id,
            amount: data.amount,
            currency_id: data.currency_id,
            destination_carrier_address_id: data.destination_carrier_address_id,
            destination_customer_address_id:
              data.destination_customer_address_id,
            destination_warehouse_id: data.destination_warehouse_id,
            original_awb: data.original_awb,
            original_client_reference: data.original_client_reference,
            original_order_no: data.original_order_no,
            comment: data.comment,
            destination_address_type: data.destination_address_type,
            collection_customer_address_type: data.collection_address_type,
            // destination_address_type: data.destination_address_type,
            collection_carrier_address_id: data.collection_carrier_address_id,
            collection_customer_address_id: data.collection_customer_address_id,
            collection_warehouse_id: data.collection_warehouse_id,
            expected_collection_date: data.expected_collection_date,
            source_of_order: data.source_of_order,
          };

          const parcel = {
            number_of_boxes: data.parcel.number_of_boxes,
            total_weight: data.parcel.total_weight,
            length: data.parcel.length,
            height: data.parcel.height,
          };

          this.formData.skus = [...data.skus];

          const collectionAddress = { ...data.collection_address };
          const destinationAddress = { ...data?.destination_address };

          this.$store.commit(SET_EDIT_DATA, {
            orderDetails,
            parcel,
            collectionAddress,
            destinationAddress,
            skus: data.skus,
          });
          this.$store.commit(
            SET_COLLECTION_ADDRESS_TYPE,
            data.collection_address_type
          );
          this.$store.commit(
            SET_DESTINATION_ADDRESS_TYPE,
            data.destination_address_type
          );

          if (data.collection_address_type === 4) {
            this.$store.commit(
              SET_CUSTOM_COLLECTION_ADDRESS,
              data.collection_address
            );
          }
          if (data.destination_address_type === 4) {
            this.$store.commit(
              SET_CUSTOM_DESTINATION_ADDRESS,
              data.destination_address
            );
          }

          if (this.returnType === "external") {
            setTimeout(() => {
              this.$refs.externalOrderDetails.getEditData();
              this.$refs.collectionAddress.getEditData();
              if (data.destination_address_type === 4) {
                this.$refs.destinationAddress.getEditData();
              }
            }, 0);
          } else {
            setTimeout(() => {
              this.$refs.internalOrderDetails.getEditData();
            }, 0);
          }

          this.pageLoader(false);
        })
        .catch(() => {
          // this.dialog = false;
          this.pageLoader(false);
        });
    },
    nextStep(type) {
      this.updateValidationStateOfSteps();
      if (this.tab === 0) {
        this.$refs.externalOrderDetails.checkErrors();
      } else if (this.tab === 1) {
        this.$refs.collectionAddress.checkErrors();
      }
      if (type == "next") {
        if (this.tab < 3) {
          this.tab += 1;
        }
      } else {
        if (this.tab > 0) {
          this.tab -= 1;
        }
      }
    },
    updateValidationStateOfSteps() {
      // console.log(`working`);
      this.$v.$touch();
      this.categories.forEach((cat) => {
        cat.valid = !cat.rules(this);
        // console.log(cat);
      });
    },
    dateFormatter(date) {
      let d = date;
      return (
        [
          d.getFullYear(),
          d.getMonth() + 1 > 9 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`,
          d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`,
        ].join("-") +
        " " +
        [
          d.getHours() > 9 ? d.getHours() : `0${d.getHours()}`,
          d.getMinutes() > 9 ? d.getMinutes() : `0${d.getMinutes()}`,
          d.getSeconds() > 9 ? d.getSeconds() : `0${d.getSeconds()}`,
        ].join(":")
      );
    },
    submitExternal() {
      const url = {
        create: "/order_management/pending_audit_returns/store",
        edit: "/order_management/pending_audit_returns/update",
      };
      this.$refs.externalOrderDetails.checkErrors();
      this.$refs.collectionAddress.checkErrors();
      if (this.$refs.destinationAddress) {
        this.$refs.destinationAddress.checkErrors();
      }

      const isExternalOrderDetailsValid =
        this.$refs.externalOrderDetails.isFormValid;
      const isCollectionAddressValid = this.$refs.collectionAddress.isFormValid;
      const isDestinationAddressValid = () => {
        if (this.destinationAddressType === 4) {
          return this.$refs.destinationAddress.isFormValid;
        }
        return true;
      };
      const isSkuValid = this.formData.skus.length;

      if (
        isExternalOrderDetailsValid &&
        isCollectionAddressValid &&
        isDestinationAddressValid() &&
        isSkuValid
      ) {
        this.pageLoader(true);
        const collection_address =
          this.$refs.collectionAddress.collection_address;
        let destination_address = null;
        if (this.destinationAddressType === 4) {
          destination_address =
            this.$refs.destinationAddress.destination_address;
        }
        const payload = {
          source_of_order: "external",
          ...this.$refs.externalOrderDetails.formData,
          expected_collection_date: this.$refs.externalOrderDetails.formData
            .expected_collection_date
            ? this.dateFormatter(
                this.$refs.externalOrderDetails.formData
                  .expected_collection_date
              )
            : null,
          parcel: {
            ...this.$refs.externalOrderDetails.parcel,
          },
          skus: this.formData.skus.map((item) => ({
            code: item.systemCode,
            sku_id: item.skuId,
            quantity: item.quantity,
          })),
          collection_address: {
            ...collection_address,
            state: collection_address?.state?.id,
            city: collection_address?.city?.id,
            area: collection_address?.area?.id,
          },
        };
        if (this.actionType === "edit") {
          payload.id = this.itemForAction.id;
        }
        if (payload.destination_address_type === 2) {
          payload.destination_customer_address_id = payload.warehouse_id;
        }
        if (payload.destination_address_type === 4) {
          payload.destination_address = {
            ...destination_address,
            state: destination_address?.state?.id,
            city: destination_address?.city?.id,
            area: destination_address?.area?.id,
          };
          payload.destination_customer_address_id = null;
          payload.destination_warehouse_id = null;
        }
        ApiService.post(url[this.actionType], payload)
          .then(() => {
            Swal.fire({
              title: `${this.actionType === "create" ? "Added" : "Updated"}`,
              text: `Return recorded`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
          })
          .then(() => {
            this.toggleModal();
            this.clearFields();
          })
          .then(() => {
            this.$store.dispatch(UPDATE_TABLE_DATA);
          })
          .finally(() => {
            this.pageLoader(false);
          });
      } else {
        return;
      }
    },
    submitInternal() {
      //check errors
      this.$refs.internalOrderDetails.checkErrors();
      const isOrderDetailsValid = this.$refs.internalOrderDetails.isInvalid;
      const formData = this.$refs.internalOrderDetails.formData;
      const orderDetails = this.$refs.internalOrderDetails.order_details;
      this.$refs.internalOrderDetails.types.destination_address_type;
      const url = {
        create: "/order_management/pending_audit_returns/store",
        edit: "/order_management/pending_audit_returns/update",
      };
      if (
        this.$store.getters.getPENDINGRETURNSSelectedCollectionAddressType === 4
      ) {
        const address =
          this.$store.getters.getPENDINGRETURNSCustomCollectionAddress;
        let hasAllProperties = true;
        const requiredProperties = [
          "name",
          "country",
          "state",
          "city",
          "address",
          "phone",
        ];
        requiredProperties.forEach((property) => {
          if (
            !address.hasOwnProperty(property) ||
            address[property] === null ||
            address[property] === ""
          ) {
            hasAllProperties = false;
            return;
          }
        });
        if (!hasAllProperties) {
          Swal.fire({
            title: `Invalid Address`,
            html: `Custom collection address is not valid`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          return;
        }
        formData.collection_address_type = 4;
        formData.collection_customer_address_id = null;
        formData.collection_warehouse_id = null;
        formData.collection_address = {
          ...address,
          state: address?.state?.id || address.state,
          city: address?.city?.id || address.city,
          area: address?.area?.id || address.area,
        };
      }
      if (
        this.$store.getters.getPENDINGRETURNSSelectedDestinationAddressType ===
        4
      ) {
        const address =
          this.$store.getters.getPENDINGRETURNSCustomDestinationAddress;
        let hasAllProperties = true;
        const requiredProperties = [
          "name",
          "country",
          "state",
          "city",
          "address",
          "phone",
        ];
        requiredProperties.forEach((property) => {
          if (
            !address.hasOwnProperty(property) ||
            address[property] === null ||
            address[property] === ""
          ) {
            hasAllProperties = false;
            return;
          }
        });
        if (!hasAllProperties) {
          Swal.fire({
            title: `Invalid Address`,
            html: `Custom destination address is not valid`,
            icon: "warning",
            showConfirmButton: true,
            // timer: 2500,
          });
          return;
        }
        formData.destination_address_type = 4;
        formData.destination_customer_address_id = null;
        formData.destination_warehouse_id = null;
        formData.destination_address = {
          ...address,
          state: address?.state?.id || address.state,
          city: address?.city?.id || address.city,
          area: address?.area?.id || address.area,
        };
      }
      if (!isOrderDetailsValid) {
        const payload = {
          source_of_order: "iq",
          customer_id: this.selectedCustomer,
          ...formData,
          orders: [...orderDetails],
        };
        if (this.actionType === "edit") {
          payload.id = this.itemForAction.id;
        }
        if (this.internalOrderSkus.length) {
          payload.orders[0].skus = this.internalOrderSkus;
        }
        this.$store.commit(SET_PAGE_LOADING, true);
        ApiService.post(url[this.actionType], payload)
          .then(({ data }) => {
            let response = "Record Updated";
            if (Array.isArray(data.messages)) {
              response = data?.messages
                .map((res) => `<div>${res}</div><br>`)
                .join("");
            }
            Swal.fire({
              title: `Response`,
              html: response,
              icon: "info",
              showConfirmButton: true,
              // timer: 2500,
            });
          })
          .then(() => {
            this.$store.dispatch(UPDATE_TABLE_DATA);
          })
          .then(() => {
            this.toggleModal();
            this.clearFields();
          })
          .finally(() => {
            this.$store.commit(SET_PAGE_LOADING, false);
          });
      }
    },
    submit() {
      if (this.returnType === "external") {
        this.submitExternal();
      } else if (this.returnType === "iq") {
        this.submitInternal();
      }
    },
    setSkus(item) {
      this.formData.skus = [...item];
    },
    clearFields() {
      if (this.returnType === "external") {
        this.$refs.externalOrderDetails.clearFields();
      } else if (this.returnType === "iq") {
        this.$refs.internalOrderDetails.clearFields();
      }
      this.$refs.collectionAddress.clearFields();
      this.formData.skus = [];
      this.tab = 0;

      this.$store.commit(SET_CUSTOM_COLLECTION_ADDRESS, {});
      this.$store.commit(SET_CUSTOM_DESTINATION_ADDRESS, {});
      this.$store.commit(SET_COLLECTION_ADDRESS_TYPE, null);
      this.$store.commit(SET_DESTINATION_ADDRESS_TYPE, 2);
    },
  },
  computed: {
    categories: function () {
      const items = [
        {
          name: "Order Details",
          valid: true,
          rules: (el) => {
            try {
              return (
                el.$refs[`externalOrderDetails`][`$v`][`formData`].$invalid ||
                el.$refs[`externalOrderDetails`][`$v`][`parcel`].$invalid
              );
            } catch {
              return true;
            }
          },
        },
        {
          name: "Collection Address",
          valid: true,
          rules: (el) => {
            try {
              return el.$refs[`collectionAddress`][`$v`][`collection_address`]
                .$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Destination Address",
          valid: true,
          rules: (el) => {
            try {
              return el.$refs[`destinationAddress`][`$v`][`destination_address`]
                .$invalid;
            } catch {
              return true;
            }
          },
        },
        {
          name: "Items",
          valid: true,
          rules: () => {
            try {
              return !this.formData.skus.length;
            } catch {
              return true;
            }
          },
        },
      ];
      if (this.returnType === "external") {
        if (this.destinationAddressType === 4) {
          return items;
        }
        return items.toSpliced(2, 1);
      } else {
        return [items[0]];
      }
    },
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    isStepperReady() {
      try {
        return this.$v ? true : false;
      } catch (error) {
        return false;
      }
    },
    backState() {
      return this.tab !== 0;
    },
    nextState() {
      // return this.tab !== 2;
      if (this.destinationAddressType === 4) {
        return this.tab !== 3;
      }
      return this.tab !== 2;
    },
    returnType() {
      return this.$store.getters.getPENDINGRETURNSCreateReturnType;
    },
    dialogWidth() {
      return this.returnType === "external" ? "1200px" : "1050px";
    },
    userId: function () {
      return this.$store.getters.currentUser.data.is_client
        ? this.$store.getters.currentUser.data.id
        : null;
    },
    serverData: function () {
      return this.$store.getters.getPENDINGRETURNSServerData;
    },
    get_is_unit_price_required() {
      let data = null;
      try {
        data = this.serverData.countries.filter(
          (country) => country.index === this.shipping_address.country
        )[0].unit_price_required;
      } catch {
        data = null;
      }
      return data;
    },
    isClient() {
      return this.$store.getters.currentUser.data.is_client;
    },
    internalOrderSkus() {
      const skus = this.$store.getters.getPENDINGRETURNSInternalOrdersSkus;
      if (skus) {
        return skus.filter((item) => item.valid);
      }
      return [];
    },
    selectedCustomer: function () {
      return this.$store.getters.getPENDINGRETURNSSelectedCustomer;
    },
    actionType: function () {
      return this.$store.getters.getPENDINGRETURNSActionType;
    },
    itemForAction: function () {
      return this.$store.getters.getItemForAction;
    },
    destinationAddressType: function () {
      return this.$store.getters
        .getPENDINGRETURNSSelectedDestinationAddressType;
    },
  },
};
</script>
